<template>
  <div v-if="isPreloaderLoad">
    <v-pagination
      v-if="gridApi || manual"
      v-model="page"
      @update:modelValue="changePage"
      :pages="
        !manual
          ? gridApi.paginationGetTotalPages()
          : Math.ceil(total / countOnPage)
      "
      :range-size="1"
      active-color="transparent"
    />

    <div
      v-if="(gridApi && gridApi.paginationGetTotalPages()) || (manual && total)"
      class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{
          !manual
            ? rowCount
            : countOnPage * (page - 1) + count
        }}
        из
        {{ !manual ? allRow : total }}
      </p>
      <p>
        Страница {{ page }} из
        {{
          !manual
            ? gridApi.paginationGetTotalPages()
            : Math.ceil(total / countOnPage)
        }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
  <div v-else class="btn-preloader">
    <mini-preloader />
  </div>

</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import {watch, computed, onBeforeMount} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import {useRoute} from "vue-router";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";
export default {
  name: "table-pagination",
  components: {
    MiniPreloader,
    VPagination,
  },
  props: {
    gridApi: {
      type: Object,
      required: false,
    },
    countOnPage: {
      required: true,
    },
    activeFilter: {
      required: false,
    },
    manual: {
      type: Boolean,
      required: false,
    },
    total: {
      type: Number,
      required: false,
    },
    count: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {
    const route = useRoute()
    const page = ref(1),
      gridApi = toRef(props, "gridApi"),
      countOnPage = toRef(props, "countOnPage"),
      manual = toRef(props, "manual"),
      rowCount = ref(null),
      allRow =ref(null),
      isPreloaderLoad =ref(false)

    const changePage = (page) => {
      if (!manual.value) {
        gridApi.value.paginationGoToPage(page - 1);
        return;
      }
      context.emit("changePage", page);
    };

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    onBeforeMount(() => {
      rowCount.value = computed(() => gridApi.value?.paginationProxy.bottomDisplayedRowIndex + 1)
      allRow.value = computed(() => gridApi.value?.paginationGetRowCount())
      isPreloaderLoad.value = true
    })

    watch(
        () => [route.params.id, route.params.product],
        () => {
          isPreloaderLoad.value = false

          setTimeout(() => {
            rowCount.value = gridApi.value?.paginationProxy.bottomDisplayedRowIndex + 1
            allRow.value = gridApi.value?.paginationGetRowCount()
            isPreloaderLoad.value = true
          }, 1000)
        }
    )

    return {
      page,
      changePage,
      rowCount,
      allRow,
      isPreloaderLoad
    };
  },
};
</script>

<style>
</style>